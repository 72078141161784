.chakra-form__label{
    color:rgb(154, 150, 150);
    font-size: 13px !important;
    font-weight: 600;
   
}
.chakra-input{
    height: 45px !important;
}
.chakra-select {
    color:rgb(154, 150, 150);
    height: 45px !important;
}
.css-1c6j008[aria-invalid="true"] {
    border: 1px solid !important;
    border-color: inherit !important;
    box-shadow: none !important
  ;
  }
  .css-k7r2wc[aria-invalid=true]{
    border: 1px solid !important;
    border-color: inherit !important;
    box-shadow: none !important
  }

