@use "../../Scss-vars/Colors.scss";

.consultation-sideNav {
    height: calc(100vh + 86px);
    .consultation_nav {
        color: Colors.$smallTextGray;
        padding: .75rem;
        &:hover{
            background-color:Colors.$primary-opacity ;
            color: Colors.$primary;
        }
    }
    .consultation_nav-dark {
        &:hover{
            background-color:Colors.$secondary-opacity !important;
            color: Colors.$secondary !important; 
        }
    }
    .active-consultation-link {
        position: relative;
        background-color:Colors.$primary-opacity ;
        color: Colors.$primary;
        &::before{
            content: "";
            position: absolute;
            border: 2px solid Colors.$primary;
            left: -2%;
            top: 0;
            bottom: 0;
        }
    }
    .active-consultation-link-dark {
        background-color:Colors.$secondary-opacity ;
        color: Colors.$secondary;
        border-left: 3px solid Colors.$secondary;
        
    }
}
.small-side-nave{
    transition: .3s ease-in-out;
    width: 220px !important;
    @media only screen and (min-width:1024px) {
        left: -360px !important;
    }
  }