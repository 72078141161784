@use "../../Scss-vars/Colors.scss";

.NavBar {
  .row {
    min-height: 70px;
    .nav-link:focus {
      color: #fff;
    }
    .share-btn {
      height: "40px";
      background-color: Colors.$primary !important;
    }
  }

  .userType {
    text-transform: capitalize;
  }
  .menu-notification { 
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .menu-notification::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.header-links {
  color: Colors.$smallTextGray;
  transition: 0.2s ease-in-out;
  padding-bottom: 32px !important;
  &:hover {
    color: Colors.$primary;
    border-bottom: 1.2px solid Colors.$primary;
  }
}
.header-links-dark {
  &:hover {
    color: Colors.$secondary;
    border-bottom: 1.2px solid Colors.$secondary;
  }
}
.header-links-active {
  color: Colors.$primary !important;
  border-bottom: 1.2px solid Colors.$primary;
  &:hover {
    color: Colors.$primary !important;
    border-bottom: 1px solid Colors.$primary;
  }

}
.header-links-active-dark {
  color: Colors.$secondary !important;
  border-bottom: 1.2px solid Colors.$secondary;
  &:hover {
    color: Colors.$secondary !important;
    border-bottom: 1px solid Colors.$secondary;
  }
}

.active-auth {
  background-color: Colors.$primary !important;
  color: #fff !important;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
}

.btnNotification{
  position: relative;
  .badge{
    position: absolute;
    bottom:30% ;
    left: 20%;
  }
}
}
.active-sideNav-link {
  color: Colors.$primary !important;
  background-color: Colors.$primary-opacity;
  border-left: solid 0.16rem Colors.$primary;
}
.active-sideNav-link-dark {
  color: Colors.$secondary !important;
  background-color: Colors.$secondary-opacity;
  border-left: solid 0.16rem Colors.$secondary;
}
