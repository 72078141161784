@use "./Scss-vars/Colors.scss";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

.pending {
  color: Colors.$info;
  background-color: Colors.$info-opacity;
  padding: 0.6rem;
  font-size: 14px;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  cursor: default;
  &:hover {
    background-color: rgba(255, 189, 40, 0.2);
  }
}

.done {
  color: Colors.$primary;
  background-color: Colors.$primary-opacity;
  text-transform: capitalize;
  padding: 0.6rem;
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  cursor: default;
  &:hover {
    background-color: Colors.$primary-opacity-hover;
  }
}
.done-dark{
  color: Colors.$secondary;
  background-color: Colors.$secondary-opacity;
  &:hover {
    background-color: Colors.$secondary-opacity-hover;
  }
}

.Warning {
  color: Colors.$Warning;
  background-color: Colors.$Warning-opacity;
  padding: 0.6rem;
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  cursor: default;
  &:hover {
    background-color: Colors.$Warning-opacity-hover;
  }
}
// .rounded {
//   border-radius: 10px !important;
// }

@media (min-width: 1200px) {
  .container {
    max-width: 1050px;
  }
}

.css-1cw84h2{
  left: 11px !important;
}

.input-drop-down-phone .country span{
  position: static !important;
}
.input-drop-down-dark .country{
  background-color: transparent !important;

}
.input-drop-down-dark .country:hover{
  background-color: rgb(181, 181, 181) !important;
}
.input-drop-down-button-dark :hover{
  background-color: rgb(72, 72, 72) !important;
}
.input-drop-down-search{
  z-index: 50;
}

.flatpickr-calendar  {
  .flatpickr-month{
    background-color: #21118D !important;
    .flatpickr-monthDropdown-months{
      background-color: #21118D !important;
      .flatpickr-monthDropdown-month{
        background-color: #21118D !important;
      }
    }
  }
  .flatpickr-innerContainer{
    .flatpickr-rContainer{
      .flatpickr-weekdays{
        background-color: #21118D;
        .flatpickr-weekdaycontainer{
          .flatpickr-weekday{
            background-color: #21118D;
            color: #ffffff;
          }
        }
      }
      .flatpickr-days{
        .dayContainer{
          .selected{
            background-color: #21118D;
          }
        }
      }
    }
  }
}
