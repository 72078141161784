@use "../../Scss-vars/Colors.scss";
.profile {
  .row {
    .avatar {
      position: relative;
      .camera {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .list-wrab {
      display: flex;
      justify-content: center;
      .list {
        list-style: none;
        .list-item {
          a {
            transition: 0.2s ease-in-out;
            color: #979797;
            &:hover {
              color: Colors.$primary!important;
              background-color: Colors.$primary-opacity;
              border-left: solid 0.16rem Colors.$primary;
            }
          }
          i{
            margin-left: 10px;
          }
        }
        .list-item-dark{
          a{
            &:hover {
              color: Colors.$secondary!important;
              background-color: Colors.$secondary-opacity;
              border-left: solid 0.16rem Colors.$secondary;
            }
          }
          }
      }
    }

    .active-profile-link {
      color: Colors.$primary !important;
      background-color: Colors.$primary-opacity;
      border-left: solid 0.16rem Colors.$primary;
    }
    .active-profile-link-dark {
      color: Colors.$secondary !important;
      background-color: Colors.$secondary-opacity;
      border-left: solid 0.16rem Colors.$secondary;
    }
    .css-ddil6j[data-checked] {
      background: Colors.$primary !important;
    }
   
 
    
    .country{
      position: relative;
      span{
        content: "<i class='fas fa-angle-down'></i>";
        position: absolute;
        top: 25%;
        right: 5%;
        font-size: 15px;
        padding: 0;
      }
    }
  }

}
.fw-primary {
  font-weight: 500;
  font-size: 15px;
  color: Colors.$primary!important;
}
.disabled-pic{
  opacity: .5;
  pointer-events: none;
  &::after{
    content: "Please waite...";
    position: absolute;
    font-size: 13px;
    left: 15%;
  }
}
.MuiInputBase-input{
  width: auto !important;
}