@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
*,html{box-sizing:border-box;padding:0;margin:0}body{scroll-behavior:smooth;font-family:"Roboto",sans-serif;min-height:100vh !important;position:relative !important;padding-bottom:53px}.footer{position:absolute;bottom:0}.chakra-ui-light{background-color:#f5f8fa !important}
.pending{color:#ffb100;background-color:rgba(255,189,40,.1);padding:.6rem;font-size:14px;font-weight:600;transition:.3s ease-in-out;text-transform:capitalize;cursor:default}.pending:hover{background-color:rgba(255,189,40,.2)}.done{color:#21118d;background-color:rgba(33,17,141,.15);text-transform:capitalize;padding:.6rem;font-size:14px;font-weight:bold;transition:.3s ease-in-out;cursor:default}.done:hover{background-color:rgba(33,17,141,.3)}.done-dark{color:#30b8ba;background-color:rgba(48,184,186,.15)}.done-dark:hover{background-color:rgba(48,184,186,.3)}.Warning{color:#c30;background-color:rgba(204,51,0,.1);padding:.6rem;font-size:14px;font-weight:bold;transition:.3s ease-in-out;cursor:default}.Warning:hover{background-color:rgba(204,51,0,.3)}@media(min-width: 1200px){.container{max-width:1050px}}.css-1cw84h2{left:11px !important}.input-drop-down-phone .country span{position:static !important}.input-drop-down-dark .country{background-color:rgba(0,0,0,0) !important}.input-drop-down-dark .country:hover{background-color:#b5b5b5 !important}.input-drop-down-button-dark :hover{background-color:#484848 !important}.input-drop-down-search{z-index:50}.flatpickr-calendar .flatpickr-month{background-color:#21118d !important}.flatpickr-calendar .flatpickr-month .flatpickr-monthDropdown-months{background-color:#21118d !important}.flatpickr-calendar .flatpickr-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month{background-color:#21118d !important}.flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-weekdays{background-color:#21118d}.flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday{background-color:#21118d;color:#fff}.flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .selected{background-color:#21118d}
.NavBar .row{min-height:70px}.NavBar .row .nav-link:focus{color:#fff}.NavBar .row .share-btn{height:"40px";background-color:#21118d !important}.NavBar .userType{text-transform:capitalize}.NavBar .menu-notification{-ms-overflow-style:none;scrollbar-width:none}.NavBar .menu-notification::-webkit-scrollbar{display:none}.NavBar .header-links{color:#8f9698;transition:.2s ease-in-out;padding-bottom:32px !important}.NavBar .header-links:hover{color:#21118d;border-bottom:1.2px solid #21118d}.NavBar .header-links-dark:hover{color:#30b8ba;border-bottom:1.2px solid #30b8ba}.NavBar .header-links-active{color:#21118d !important;border-bottom:1.2px solid #21118d}.NavBar .header-links-active:hover{color:#21118d !important;border-bottom:1px solid #21118d}.NavBar .header-links-active-dark{color:#30b8ba !important;border-bottom:1.2px solid #30b8ba}.NavBar .header-links-active-dark:hover{color:#30b8ba !important;border-bottom:1px solid #30b8ba}.NavBar .active-auth{background-color:#21118d !important;color:#fff !important;text-decoration:none}.NavBar .active-auth:hover{color:#fff}.NavBar .btnNotification{position:relative}.NavBar .btnNotification .badge{position:absolute;bottom:30%;left:20%}.active-sideNav-link{color:#21118d !important;background-color:rgba(33,17,141,.15);border-left:solid .16rem #21118d}.active-sideNav-link-dark{color:#30b8ba !important;background-color:rgba(48,184,186,.15);border-left:solid .16rem #30b8ba}
.myActivityHome{margin-top:100px !important}

.profile .row .avatar{position:relative}.profile .row .avatar .camera{position:absolute;bottom:0;right:0;font-size:12px;width:22px;height:22px;display:flex;justify-content:center;align-items:center;color:#fff;cursor:pointer}.profile .row .list-wrab{display:flex;justify-content:center}.profile .row .list-wrab .list{list-style:none}.profile .row .list-wrab .list .list-item a{transition:.2s ease-in-out;color:#979797}.profile .row .list-wrab .list .list-item a:hover{color:#21118d !important;background-color:rgba(33,17,141,.15);border-left:solid .16rem #21118d}.profile .row .list-wrab .list .list-item i{margin-left:10px}.profile .row .list-wrab .list .list-item-dark a:hover{color:#30b8ba !important;background-color:rgba(48,184,186,.15);border-left:solid .16rem #30b8ba}.profile .row .active-profile-link{color:#21118d !important;background-color:rgba(33,17,141,.15);border-left:solid .16rem #21118d}.profile .row .active-profile-link-dark{color:#30b8ba !important;background-color:rgba(48,184,186,.15);border-left:solid .16rem #30b8ba}.profile .row .css-ddil6j[data-checked]{background:#21118d !important}.profile .row .country{position:relative}.profile .row .country span{content:"<i class='fas fa-angle-down'></i>";position:absolute;top:25%;right:5%;font-size:15px;padding:0}.fw-primary{font-weight:500;font-size:15px;color:#21118d !important}.disabled-pic{opacity:.5;pointer-events:none}.disabled-pic::after{content:"Please waite...";position:absolute;font-size:13px;left:15%}.MuiInputBase-input{width:auto !important}
.chakra-form__label{color:#9a9696;font-size:13px !important;font-weight:600}.chakra-input{height:45px !important}.chakra-select{color:#9a9696;height:45px !important}.css-1c6j008[aria-invalid=true]{border:1px solid !important;border-color:inherit !important;box-shadow:none !important}.css-k7r2wc[aria-invalid=true]{border:1px solid !important;border-color:inherit !important;box-shadow:none !important}
.consultation-sideNav{height:calc(100vh + 86px)}.consultation-sideNav .consultation_nav{color:#8f9698;padding:.75rem}.consultation-sideNav .consultation_nav:hover{background-color:rgba(33,17,141,.15);color:#21118d}.consultation-sideNav .consultation_nav-dark:hover{background-color:rgba(48,184,186,.15) !important;color:#30b8ba !important}.consultation-sideNav .active-consultation-link{position:relative;background-color:rgba(33,17,141,.15);color:#21118d}.consultation-sideNav .active-consultation-link::before{content:"";position:absolute;border:2px solid #21118d;left:-2%;top:0;bottom:0}.consultation-sideNav .active-consultation-link-dark{background-color:rgba(48,184,186,.15);color:#30b8ba;border-left:3px solid #30b8ba}.small-side-nave{transition:.3s ease-in-out;width:220px !important}@media only screen and (min-width: 1024px){.small-side-nave{left:-360px !important}}
.midical-nav{color:#8f9698;transition:.2s ease-in-out;display:flex;align-items:center;margin:9px;margin-bottom:0;font-weight:600;font-size:12.75px}.midical-nav:hover{color:#21118d;border-bottom:1.2px solid #21118d}.midical-nav:hover .circle{background-color:#21118d}.midical-nav-dark:hover{color:#30b8ba;border-bottom:1.2px solid #30b8ba}.midical-nav-dark:hover .circle{background-color:#30b8ba}.midical-nav-small-dark:hover{color:#fff;background-color:#30b8ba}.midical-active{color:#21118d !important;border-bottom:1.2px solid #21118d}.midical-active div{background-color:#21118d}.midical-active-dark{color:#30b8ba !important;border-bottom:1.2px solid #30b8ba}.midical-active-dark div{background-color:#30b8ba}.midical-active-small{color:#fff !important;border-bottom:1.2px solid #21118d;background-color:#21118d}.midical-active-small div{background-color:#21118d}.midical-active-small-dark{color:#fff !important;border-bottom:1.2px solid #30b8ba;background-color:#30b8ba}.midical-active-small-dark div{background-color:#30b8ba}.medical-small-wrap{max-height:150px;overflow-y:auto}@media only screen and (max-width: 425px){.add-wrap{width:100% !important}}
.input-search{position:relative}.input-search input{position:static !important}.input-search .icon-search{font-size:20px;position:absolute;top:25%;left:6%}
.dropzone{padding:25px 0px;border:1px dashed #cecece;border-radius:10px;width:100%}
.custom-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 220px !important;
  }
  
  .custom-editor {
    height: 200px !important;
    border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
  }
  
