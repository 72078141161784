@use "../../../../../Scss-vars/Colors.scss";

.midical-nav{
    color: Colors.$smallTextGray;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    margin: 9px;
    margin-bottom:0 ;
    font-weight: 600;
    font-size: 12.75px;
    &:hover {
        color: Colors.$primary;
        border-bottom: 1.2px solid Colors.$primary;
        .circle{
            background-color: Colors.$primary;
        }
      }
  
}
.midical-nav-dark{
    &:hover {
        color: Colors.$secondary;
        border-bottom: 1.2px solid Colors.$secondary;
        .circle{
            background-color: Colors.$secondary;
        }
      }
}
.midical-nav-small-dark{
    &:hover {
        color: Colors.$white;
        background-color: Colors.$secondary;
      }
}

.midical-active{
    color: Colors.$primary !important;
    border-bottom: 1.2px solid Colors.$primary;
    div{
        background-color: Colors.$primary;
    }
}
.midical-active-dark{
    color: Colors.$secondary !important;
    border-bottom: 1.2px solid Colors.$secondary;
    div{
        background-color: Colors.$secondary;
    }
}
.midical-active-small{
    color: Colors.$white !important;
    border-bottom: 1.2px solid Colors.$primary;
    background-color: Colors.$primary;
    div{
        background-color: Colors.$primary;
    }
}
.midical-active-small-dark{
    color: Colors.$white !important;
    border-bottom: 1.2px solid Colors.$secondary;
    background-color: Colors.$secondary;
    div{
        background-color: Colors.$secondary;
    }
}

.medical-small-wrap{
    max-height:150px ;
    overflow-y: auto;
}
@media only screen and (max-width: 425px) {
    .add-wrap{
        width: 100% !important;
    }
  }

